<template>
  <div
    class="app-thumbnail"
    :style="styles"
    :data-use-placeholder="usePlaceholder"
    :data-placeholder="placeholder"
  >
    <img
      v-if="!usePlaceholder && src"
      class="app-thumbnail-image"
      :src="src"
      :alt="props.alt"
      @error="usePlaceholder = true"
    />
    <component :is="props.icon" v-else />
  </div>
</template>

<script>
import IconPlaceholderAirline from './icons/placeholders/IconPlaceholderAirline.vue'
import IconPlaceholderCity from './icons/placeholders/IconPlaceholderCity.vue'
import IconPlaceholderHotel from './icons/placeholders/IconPlaceholderHotel.vue'

const placeholders = {
  airline: {
    alt: 'airline',
    icon: IconPlaceholderAirline,
  },
  hotel: {
    alt: 'hotel',
    icon: IconPlaceholderHotel,
  },
  city: {
    alt: 'city',
    icon: IconPlaceholderCity,
  },
}

export default {
  name: 'AppFallbackImage',
  props: {
    placeholder: {
      type: String,
      required: true,
      validator: value => Object.keys(placeholders).includes(value),
    },
    size: { type: String, default: '64' },
    src: String,
  },
  data() {
    return {
      IconPlaceholderAirline,
      usePlaceholder: false,
    }
  },
  computed: {
    styles() {
      const styles = {}
      styles.height = styles.width = this.size + 'px'
      return styles
    },
    pixelSize: vm => vm.size + 'px',
    props: vm => placeholders[vm.placeholder],
  },
}
</script>

<style scoped lang="scss">
.app-thumbnail {
  --padding: 5px;
  box-shadow: var(--thumbnail-box-shadow);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &[data-use-placeholder],
  &:not([data-use-placeholder])[data-placeholder='airline'] {
    padding: 5px;
  }
  color: var(--color-icon);
}

.app-thumbnail-image {
  width: 100%;
  height: 100%;
  object-position: center;
  &[data-placeholder='airline'] {
    object-fit: contain;
  }
  &[data-placeholder='hotel'],
  &[data-placeholder='city'] {
    object-fit: cover;
  }
}
</style>
